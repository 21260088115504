/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202410081815-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

/**
 * The field configurations for this connection configuration.  These define the form fields used by the connection configuration interface
 */
export interface ConfigFieldV1 {
    /**
     * Whether a null value is allowed
     */
    "allowNull": boolean;
    /**
     * Description of the field to be used in connection configuration interface
     */
    "description"?: string;
    /**
     * The name of the field.  It is set to the last component of the Path
     */
    "name"?: string;
    /**
     * JSON Path to the configuration parameter, omitting array indices
     */
    "path": string;
    /**
     * Field configuration type
     */
    "type": TypeEnum;
    /**
     * Configuration field value
     */
    "value"?: any;
}

export enum TypeEnum {
    SecretConfigFieldV1 = 'SecretConfigFieldV1' as any,
    StringConfigFieldV1 = 'StringConfigFieldV1' as any,
    DoubleConfigFieldV1 = 'DoubleConfigFieldV1' as any,
    LongConfigFieldV1 = 'LongConfigFieldV1' as any,
    BooleanConfigFieldV1 = 'BooleanConfigFieldV1' as any
}
